import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "400px",
    padding: 0,
    textAlign: "left",
  },
  header: {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.events.grayPrimary}`,
    height: "30px",
  },
  closeIcon: {
    font: "24px",
    color: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.blue.dark,
    paddingTop: "5px",
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  directions: {
    color: theme.palette.text.primary,
    lineHeight: "30px",
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  form: {
    marginTop: theme.spacing(2.5),
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: "4px",
    fontSize: "14px",
    fontWeight: 500,
  },
  middleLabel: {
    marginTop: theme.spacing(2.5),
    marginBottom: 0,
  },
  learnLink: {
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.events.blueSecondary,
    padding: 10,
  },
  preview: {
    height: "250px",
    marginTop: "4px",
    border: `1px solid ${theme.palette.events.grayPrimary}`,
    backgroundColor: theme.palette.events.whiteTertiary,
    color: theme.palette.events.grayTertiary,
    boxShadow: `4px 4px 4px 0px ${theme.palette.boxShadow.default}`,
    borderRadius: "5px",
    cursor: "not-allowed",
    userSelect: "none",
  },
  previewIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    pointerEvents: "none",
  },
}));

export default useStyles;
