import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogContent-root": {
      padding: "28px 24px 0 24px",
    },
  },
  content: {
    backgroundColor: theme.palette.events.dialogHeaderBackground,
  },
}));

export const styles = {
  actions: {
    backgroundColor: "events.dialogHeaderBackground",
  },
  launchBtn: {
    padding: "10px 40px",
    width: "129px",
  },
  cancelBtn: {
    padding: "10px 40px",
    width: "127px",
  },
};
