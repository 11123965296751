import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1.25),
  },
  dialogContent: {
    padding: "0px !important",
  },
}));

export const styles = {
  closeIcon: {
    color: "events.textPrimary",
  },
  dialogHeader: {
    backgroundColor: "events.graySecondary",
    borderBottom: "1px solid",
    borderColor: "border.default",
  },
  launchBtn: {
    padding: "10px 40px",
    width: "129px",
  },
  cancelBtn: {
    padding: "10px 40px",
    width: "127px",
  },
};
