import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "340px",
    padding: "20px",
    position: "relative",
    backgroundColor: theme.palette.events.graySecondary,
  },
  accordion: {
    width: "100%",
    boxShadow: `4px 4px 4px 0px ${theme.palette.boxShadow.default}`,
    border: "1px solid",
    borderColor: theme.palette.events.grayPrimary,
  },
}));

export const styles = {
  accordionSummary: {
    height: "59px",
  },
  formContainer: {
    marginTop: "20px !important",
  },
  checkbox: {
    width: 20,
    height: 20,
    display: "flex",
    backgroundColor: "events.whiteTertiary",
    borderRadius: "2px",
    border: "1px solid",
    borderColor: "events.grayTertiary",
  },
  checkboxIcon: {
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    backgroundColor: "events.blueSecondary",
  },
  actionBtn: {
    width: "127px",
    padding: "10px 40px",
  },
};
