import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  closeButton: {
    color: theme.palette.text.primary,
  },
  startBroadcastDialogContent: {
    padding: 0,
  },
  startBroadcastDialog: {
    width: "400px",
    borderRadius: "10px",
    padding: 0,
  },
  headerDialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "30px",
  },
  dialogContentText: {
    padding: "20px",
    gap: "10px",
    display: "grid",
    paddingBottom: 0,
    color: theme.palette.text.primary,
  },
  videoContainer: {
    width: "300px",
    height: "225px",
    margin: "20px",
    paddingTop: 0,
    borderRadius: "5px",
    paddingBottom: 0,
    position: "relative",
  },
  video: {
    display: "flex",
    justifyContent: "center",
  },
  dialogActionContainer: {
    justifyContent: "center",
    padding: "20px",
  },
  infoBox: {
    margin: "20px",
    background: theme.palette.events.blueAccent4,
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  infoIcon: {
    color: theme.palette.events.bluePrimary,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlayText: {
    color: "#fff",
    textAlign: "center",
    width: "225px",
  },
  urlLink: {
    color: theme.palette.events.blueSecondary,
    textDecoration: "none",
    padding: 10,
    fontWeight: 500,
  },
  deviceControls: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  startButton: {
    width: "184px",
  },
}));
