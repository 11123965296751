import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contextMenuItem: {
    paddingTop: "15px",
    paddingRight: "13px",
    paddingBottom: "15px",
    paddingLeft: "13px",
    "&:hover": {
      background: theme.palette.events.bluePrimary,
      color: theme.palette.events.whiteTertiary,
      "& svg": {
        fill: `${theme.palette.events.whiteTertiary} !important`,
      },
    },
  },
  contextMenuIcon: {
    minWidth: 25,
    color: "inherit",
  },
}));
