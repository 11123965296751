import { ChangeEvent, useState } from "react";
import classNames from "classnames";
import validator from "validator";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import useStyles from "./styles";

interface Props {
  onChange: (url: string, confirmDisabled: boolean) => void;
}

const SharePresentContentDialogContent = ({ onChange }: Props): JSX.Element => {
  const [previewURL, setPreviewURL] = useState("");
  const [touched, setTouched] = useState(false);
  const { t } = useI18n(["event", "url"]);
  const styles = useStyles();

  const isValidURL = (url: string) => {
    const urlValidatorOptions = {
      protocols: ["http", "https"],
      require_protocol: true,
    };

    return validator.isURL(url, urlValidatorOptions);
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const incomingURL = evt.target.value;
    setTouched(true);
    setPreviewURL(incomingURL);
    onChange(incomingURL, !isValidURL(incomingURL));
  };

  return (
    <Container className={styles.container}>
      <Box>
        <Typography variant="h3">{t("event:present.content.title")}</Typography>
      </Box>
      <Box
        display="flex"
        alignItems="start"
        flexDirection="column"
        className={styles.content}
      >
        <Typography variant="body1" className={styles.directions}>
          {t("event:present.content.description")}
        </Typography>
        <Typography variant="body1" className={styles.directions}>
          {t("event:present.content.directions")}
        </Typography>
        <Link
          className={styles.link}
          openInNewTab
          href={t("url:present.content.help.article")}
          data-testid="learn-more-url"
          aria-label={t("event:present.content.learn.more")}
        >
          <Typography variant="body1" underline className={styles.linkText}>
            {t("event:present.content.learn.more")}
          </Typography>
        </Link>
        <Container className={styles.form}>
          <InputLabel htmlFor="url" className={styles.label}>
            {t("event:present.content.link")}
          </InputLabel>
          <Input
            id="url"
            fullWidth
            size="sm"
            placeholder={t("event:present.content.url.placeholder")}
            onChange={handleChange}
            error={
              touched && !isValidURL(previewURL)
                ? `${t("event:embed.content.invalid.url")}`
                : false
            }
          />
          <InputLabel className={classNames(styles.label, styles.previewLabel)}>
            {t("event:preview")}
          </InputLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            className={styles.preview}
          >
            {isValidURL(previewURL) ? (
              <iframe
                src={previewURL}
                frameBorder={0}
                title="URL preview"
                className={styles.previewIframe}
              />
            ) : (
              <Typography variant="subtitle1">
                {t("event:embed.content.preview")}
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default SharePresentContentDialogContent;
