import { fontWeightBold } from "@remo-co/ui-core/src/theme/fontWeights";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "400px",
    paddingBottom: 0,
  },
  content: { paddingTop: theme.spacing(2), textAlign: "left" },
  directions: {
    paddingBottom: theme.spacing(1.25),
    color: `${theme.palette.events.whiteTertiary} !important`,
  },
  link: {
    textDecoration: "none",
  },
  linkText: {
    fontWeight: fontWeightBold,
    color: theme.palette.events.bluePrimary,
    padding: 10,
    textDecoration: "none",
  },
  form: {
    marginTop: theme.spacing(2.5),
  },
  label: {
    marginBottom: 4,
    color: theme.palette.white,
  },
  previewLabel: {
    marginTop: 20,
  },
  preview: {
    height: "250px",
    border: `1px solid ${theme.palette.events.whiteTertiary}`,
    backgroundColor: theme.palette.events.lightBlack,
    borderRadius: "5px",
    cursor: "not-allowed",
    userSelect: "none",
  },
  previewIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    pointerEvents: "none",
  },
}));

export default useStyles;
